import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Networkhospital from "./pages/Networkhospital";
import Escalation_matrix from "./pages/Escalation_matrix";
import Members_covered from "./pages/Members_covered";
import Policydetails from "./pages/Policydetails";
import Downloadforms from "./pages/Downloadforms";
import Claimstatus from "./pages/Claimstatus";
import Mycoverage from "./pages/Mycoverage";
import Intimate_my_claims from "./pages/Intimate_my_claims";
import Leaves from "./pages/Leaves_employee";
import Attendence_employee from "./pages/Attendence_employee";
import Hr_Circular from "./pages/Hr_Circular";
import Wellness_corner from "./pages/Wellness_corner";
import Claim_submission from "./pages/Claim_Submission";
import { useLocation } from "react-router-dom";
import { AppContext, mainAPikey } from "./ContextApi";

function App() {
  const location = useLocation();
  const { token, setToken, setRequestOptions } = useContext(AppContext);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("authorizationToken"));

  useEffect(() => {
    if (mainAPikey) {
      fetch(`${process.env.REACT_APP_API_URL}/${mainAPikey}/employee/favicon`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch favicon');
          }
          return response.json();
        })
        .then(data => {
          const faviconLink = document.querySelector("link[rel='icon']");
          faviconLink.href = data.favicon || 'default-favicon.ico';
        })
        .catch(error => console.error('Error fetching favicon:', error));
    }
  }, [mainAPikey]);

  const params = new URLSearchParams(location.search);
  const encodedData = params.get('data');

  useEffect(() => {
    if (encodedData) {
      const decodedData = atob(decodeURIComponent(encodedData));
      localStorage.setItem('authorizationToken', `Bearer ${decodedData}`);
      setIsAuthenticated(decodedData);
      setToken(decodedData);

      setRequestOptions(prevOptions => ({
        ...prevOptions,
        headers: {
          ...prevOptions.headers,
          Authorization: `Bearer ${decodedData}`,
        },
      }));

      navigate("/policy-details");
    }
  }, [encodedData, navigate, setToken, setRequestOptions]);

  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: 'en,hi,mr,ta,te,gu,bn',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      }, 'google_translate_element');
    };

    const script = document.createElement("script");
    script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);
    
    window.googleTranslateElementInit = googleTranslateElementInit;

    return () => {
      document.body.removeChild(script);
      delete window.googleTranslateElementInit;
    };
  }, []);

  return (
    <div className="App">
      <div id="google_translate_element"></div>
      <Routes>
        <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/network-hospital" element={isAuthenticated ? <Networkhospital /> : <Navigate to="/" />} />
        <Route path="/escalation-matrix" element={isAuthenticated ? <Escalation_matrix /> : <Navigate to="/" />} />
        <Route path="/members-covered" element={isAuthenticated ? <Members_covered /> : <Navigate to="/" />} />
        <Route path="/policy-details" element={isAuthenticated ? <Policydetails /> : <Navigate to="/" />} />
        <Route path="/download-form" element={isAuthenticated ? <Downloadforms /> : <Navigate to="/" />} />
        <Route path="/claim-status" element={isAuthenticated ? <Claimstatus /> : <Navigate to="/" />} />
        <Route path="/mycoverage" element={isAuthenticated ? <Mycoverage /> : <Navigate to="/" />} />
        <Route path="/intimate-myclaims" element={isAuthenticated ? <Intimate_my_claims /> : <Navigate to="/" />} />
        <Route path="/leave-page" element={isAuthenticated ? <Leaves /> : <Navigate to="/" />} />
        <Route path="/attendence-employee" element={isAuthenticated ? <Attendence_employee /> : <Navigate to="/" />} />
        <Route path="/hr-circular" element={isAuthenticated ? <Hr_Circular /> : <Navigate to="/" />} />
        <Route path="/wellness-corner" element={<Wellness_corner />} />
        <Route path="/claim-submission" element={<Claim_submission />} />
      </Routes>
    </div>
  );
}

export default App;
