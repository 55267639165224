import React from 'react' ;
import Stepper from "react-stepper-horizontal" ;

const CustomStepper = (props) => {
  return (
    <>
        <Stepper
      { ...props }
      connectorStateColors={true}
      connectorStyleConfig={{
        completedColor: '#ffbd13',
        activeColor: '#5096FF',
        disabledColor: '#eee'
      }}
      styleConfig={{
        activeBgColor: '#5096FF',
        completedBgColor: '#ffbd13',
        inactiveBgColor: '#eee',
        activeTextColor: '#111',
        completedTextColor: '#222',
        inactiveTextColor: '#444',
        textStyle: { fontWeight: 'bold' }
      }}
      />
    </>
  )
}

export default CustomStepper