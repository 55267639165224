import React, { useEffect, useState, useContext } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { PiUploadBold } from "react-icons/pi";
import { ToastContainer, toast } from "react-toastify";
import { AppContext } from "../ContextApi";
import DeletePopup from "../components/DeleteMsgPopup";
import {
  ADD_CLAIM_DOCUMENT_API,
  CLAIM_SUBMISSION_DOCUMENTS_LIST_API,
  DELETE_KYC_DOCUMENT_API,
  MAIN_CLAIM_SUBMISSION_API,
} from "../Constants";
import { noUploadDocIcon } from "../Assets";
import useModal from "../Custom hooks/useModal";
import ClaimExistModal from "../components/ClaimExistModal";
import DataLoadComponent from "../components/DataLoading";
import { FaEye } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import DataTable from "react-data-table-component";
import { MdOutlineDone } from "react-icons/md";
import { mainAPikey } from "../ContextApi";
// import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullLoader from "../components/FullLoader";
import SaveDocmentPopup from "../components/SaveDocumentPopup";

const Claim_document_form = ({ requestOptions, setCurrentFormIndex }) => {
  const {
    isOpen,
    openModal,
    closeModal,
    isDeleteModalOpen,
    closeDocPopup,
    openDeletePopup,
    closeDeletePopup,
  } = useModal();
  const [isDoclistView, setDocListView] = useState(true);
  const [documentListData, setDocumentListData] = useState([]);
  const [docPath, setDocpath] = useState("");
  const [docId, setDocId] = useState(null);
  const [showMainPopup, setMainPopup] = useState(false);
  const [is2docSubmit, set2DocSubmit] = useState(false);
  const [isMainError, setMainError] = useState(false);

  const [SaveDocPopup, setSaveDocPopup] = useState(false);
  const [mainSaveDoc, setMainSaveDoc] = useState(false);

  const [files, setFiles] = useState({
    Medicinebills: [],
    Consultation: [],
    Labreport: [],
    PaidReceipt: [],
    Dischargecard: [],
    Indorecasepapers: [],
    Hospitalbills: [],
    Otherdocuments: [],
    // Add more document types here as needed
  });

  const [DataLoading, setdataLoading] = useState(false);
  const [isActiveFullLoader, setActiveFullLoader] = useState(false);
  const [submissionMsg, setSubmissionMsg] = useState("");
  const [pending, setPending] = useState(true);
  const docTypes = [
    {
      Medicinebills: "Medicine Bills",
      Consultation: "Consultation",
      Labreport: "Lab Report",
      PaidReceipt: "Paid Receipt",
      Dischargecard: "Discharge Card",
      Indorecasepapers: "Indoor Case Papers",
      Hospitalbills: "Hospital Bills and Receipts",
      Otherdocuments: "Other Documents",
    },
  ];

  const { intimationId, setIntimationId } = useContext(AppContext);

  const handleFileChange = (e, documentType) => {
    const newFiles = Array.from(e.target.files);

    setFiles((prevFiles) => {
      // Filter out files with the same name
      const uniqueNewFiles = newFiles.filter(
        (newFile) =>
          !prevFiles[documentType].find(
            (prevFile) => prevFile.name === newFile.name
          )
      );
      return {
        ...prevFiles,
        [documentType]: [...prevFiles[documentType], ...uniqueNewFiles],
      };
    });
  };

  const documentTypes = Object.keys(files);

  const handleFileDelete = (e, documentType, file, index) => {
    e.preventDefault();
    const updatedDoc = files[documentType].filter(
      (myfile) => myfile.name !== file.name
    );
    setFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: updatedDoc,
    }));
  };

  const handleCloseDocPopUp = () => {
    setSaveDocPopup(false);
  };

  const [encodedFiles, setEncodedFiles] = useState([]);

  const claimDocuments = new FormData();
  claimDocuments.append("intimation_id", intimationId);

  const newClaimDocData = {
    data: {
      intimation_id: intimationId,
    },
    document: encodedFiles,
  };

  useEffect(() => {
    const readFiles = async () => {
      const readFile = (file, documentType) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64Data = reader.result.split(",")[1];
            const documentData = {
              file_name: file.name,
              document_type: docTypes[0] ? docTypes[0][documentType] : "",
              title: docTypes[0] ? docTypes[0][documentType] : "",
              file: base64Data,
            };
            resolve(documentData);
          };
          reader.onerror = () =>
            reject(new Error(`Error reading file: ${file.name}`));
          reader.readAsDataURL(file);
        });
      };

      const promises = [];
      Object.entries(files).forEach(([documentType, fileInputs]) => {
        if (fileInputs.length > 0) {
          Array.from(fileInputs).forEach((file) => {
            promises.push(readFile(file, documentType));
          });
        }
      });

      try {
        const documentDataArray = await Promise.all(promises);
        setEncodedFiles((prevState) => {
          // Only update state if the data has actually changed
          if (JSON.stringify(prevState) !== JSON.stringify(documentDataArray)) {
            return documentDataArray;
          }
          return prevState;
        });
      } catch (error) {
        console.error("Error reading files", error);
      }
    };

    readFiles();
  }, [files, docTypes]);

  const columns = [
    {
      name: "Sr No",
      maxWidth: "100px",
      cell: (row, index) => <div className="sr-no">{index + 1}</div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: "Document Name",
      selector: (row) => row.document_type,
    },
    {
      name: "Attachment",
      cell: (row) => (
        <div
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <a
            href={`${docPath}/${row.file_name}`}
            target="_blank"
            rel="noopener noreferrer"
            className="download-attachment-link"
          >
            {row.file_name}
          </a>
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="delete-kyc-doc-btn"
          type="button"
          onClick={() => handleDeletePopup(row.id)}
        >
          <span>
            <MdDelete />
          </span>
          Delete
        </button>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "42px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px", // override the cell padding for head cells
        paddingRight: "15px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px", // override the cell padding for head cells
        paddingRight: "15px",
      },
    },
    0: {
      style: {
        minWidth: "100px",
      },
    },
  };

  const requestOptionsCopy = { ...requestOptions };
  requestOptionsCopy.body = claimDocuments;

  const handleDeletePopup = (id) => {
    // e.preventDefault();
    setDocId(id);
    console.log(id);
    openDeletePopup();
  };

  const handleView = () => {
    setDocListView(!isDoclistView);
  };
  const data = {
    intimation_id: intimationId,
  };

  const requestOptionsCopy2 = { ...requestOptions };
  requestOptionsCopy2.method = "POST";
  requestOptionsCopy2.body = JSON.stringify(data);

  requestOptionsCopy2.processData = false;
  requestOptionsCopy2.contentType = false;

  async function getKycListDoc() {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/${mainAPikey}` + CLAIM_SUBMISSION_DOCUMENTS_LIST_API,
        requestOptionsCopy2
      );
      const res = await response.json();
      if(res.status === 429){
        toast.error("Oops! You have reached maximum limit, please try again after sometime.")
      }
      else if (res.status) {
        setDocumentListData(res.data);
        setPending(false);
        setDocpath(res?.path);
      } else {
        setPending(false);
        console.log("Res : False", res);
      }
    } catch (error) {
      setPending(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getKycListDoc();
  }, [isDoclistView]);

  const onSubmit = (e) => {
    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(newClaimDocData);

    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function save_document_upload() {
      setdataLoading(true);

      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/${mainAPikey}` + ADD_CLAIM_DOCUMENT_API,
          requestOptionsCopy
        );
        const res = await response.json();
        if(res.status === 429){
          toast.error("Oops! You have reached maximum limit, please try again after sometime.")
        }
        else if (res.status) {
          setSubmissionMsg(res.message);
          setdataLoading(false);

          openModal();
          setMainPopup(true);
          set2DocSubmit(true);
          setFiles({
            Medicinebills: [],
            Consultation: [],
            Labreport: [],
            PaidReceipt: [],
            Dischargecard: [],
            Indorecasepapers: [],
            Hospitalbills: [],
            Otherdocuments: [],
          });
          // getKycListDoc();
          setDocListView(true);
        } else {
          toast.error(res.message);
          setdataLoading(false);
          setSubmissionMsg(res.message);
          openModal();
        }
      } catch (error) {
        console.error(error);
      }
    }
    save_document_upload();
    e.preventDefault();
  };

  const handleDeleteDoc = (id) => {
    const data = {
      rowID: id,
    };

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(data);

    async function deleteDoc() {
      // setDocLoading(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/${mainAPikey}` + DELETE_KYC_DOCUMENT_API,
          requestOptionsCopy
        );
        const res = await response.json();

        if (res.status) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          closeDeletePopup();
          setTimeout(() => {
            getKycListDoc();
          }, 100);
          // setDocLoading(false);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    deleteDoc();
  };

  const handleMainSubmission = () => {
    // e.preventDefault();
    const data = {
      intimation_id: intimationId,
    };

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(data);

    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function claimMainSubmission() {
      setdataLoading(true);
      setActiveFullLoader(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/${mainAPikey}` + MAIN_CLAIM_SUBMISSION_API,
          requestOptionsCopy
        );
        set2DocSubmit(false);
        const res = await response.json();
        if(res.status === 429){
          toast.error("Oops! You have reached maximum limit, please try again after sometime.")
        }
        else if (res.status) {
          console.log(res.message);
          setSubmissionMsg(res.message);
          setdataLoading(false);
          openModal();
          setSaveDocPopup(false);
          setActiveFullLoader(false);
          setMainPopup(true);
          getKycListDoc();
          setTimeout(() => {
            setCurrentFormIndex(0);
            window.location.reload();
            setIntimationId(null);
          }, 4000);
        } else {
          // toast.error(res.message)
          setMainPopup(true);
          setMainError(true);
          setdataLoading(false);
          setSaveDocPopup(false);
          setSubmissionMsg(res.message);
          setActiveFullLoader(false);
          openModal();
        }
      } catch (error) {
        console.error(error);
      }
    }
    claimMainSubmission();
  };

  return (
    <>
      <div className="claim-document-main-wrapper-cover">
        <div className="documents-upload-main-cover-wrapper">
          <div className="document-upload-row-cover">
            <div className="view-document-btn d-flex justify-content-between">
              {isDoclistView ? (
                <>
                  <div className="document-btn-header d-flex w-100 justify-content-between">
                    {documentListData?.length > 0 && (
                      <button
                        type="button"
                        onClick={() => setSaveDocPopup(true)}
                      >
                        <span>
                          <MdOutlineDone />
                        </span>
                        Submit Claim
                      </button>
                    )}
                    <button type="button" onClick={handleView}>
                      <span>
                        <MdOutlineFileUpload />
                      </span>
                      Add Documents
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setDocListView(!isDoclistView)}
                  >
                    <span className="mx-2">
                      <FaEye />
                    </span>
                    View Documents
                  </button>
                </>
              )}
            </div>
            {isDoclistView ? (
              <div className="doc-listview-main-wrapper">
                <DataTable
                  columns={columns}
                  data={documentListData}
                  customStyles={customStyles}
                  pagination
                  // progressPending={pending}
                  progressComponent={<DataLoadComponent />}
                />
                <div class="footer-buttons mt-0">
                  <button
                    type="button"
                    class="prev"
                    onClick={() => setCurrentFormIndex(0)}
                  >
                    Previous
                  </button>
                </div>
              </div>
            ) : (
              <>
                <form className="form-step" onSubmit={onSubmit}>
                  <div className="form-step3-main-cover-wrapper mb-3">
                    <h5 className="header-claim-title">
                      Upload Below Documents
                    </h5>
                  </div>
                  <div className="row d-flex justify-content-center">
                    {documentTypes.length > 0 &&
                      documentTypes.map((documentType, ind) => (
                        <div className="col-lg-5 mb-4" key={documentType}>
                          <div className="upload-document-boxlist-cover">
                            <div className="upload-document-list-header">
                              <h6>{docTypes[0][documentType]}</h6>
                              <div className="upload-document-btn-box">
                                <button>
                                  <span>
                                    <PiUploadBold />
                                  </span>
                                  <input
                                    type="file"
                                    name="claim_upload_file[]"
                                    onChange={(e) =>
                                      handleFileChange(e, documentType)
                                    }
                                    style={{
                                      opacity: 0,
                                      width: 100,
                                      position: "absolute",
                                      left: "-8px",
                                      top: 3,
                                    }}
                                    multiple
                                  />
                                  Upload
                                </button>
                              </div>
                            </div>

                            <div
                              className={
                                `${files[documentType]?.length > 2}`
                                  ? "uploaded-documents-list-box height-style"
                                  : "uploaded-documents-list-box"
                              }
                            >
                              {files[documentType].length > 0 ? (
                                files[documentType].map((file, index) => (
                                  <div
                                    className="uploaded-documents-list"
                                    key={index}
                                  >
                                    <h6>{file.name}</h6>
                                    <button
                                      onClick={(e) =>
                                        handleFileDelete(
                                          e,
                                          documentType,
                                          file,
                                          index
                                        )
                                      }
                                    >
                                      <FaRegCircleXmark />
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <div className="no-document-upload-box d-flex flex-column justify-content-center">
                                  <img
                                    src={noUploadDocIcon}
                                    alt="Doc icon"
                                  ></img>
                                  <h6
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "center",
                                    }}
                                  >
                                    No Added Files
                                  </h6>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="submit-btn-box d-flex justify-content-around">
                    {DataLoading ? (
                      <div className="data-loader d-flex justify-content-center flex-column">
                        <DataLoadComponent />
                        <br></br>
                        <p style={{ fontSize: "14px" }}>please wait..</p>
                      </div>
                    ) : (
                      <input
                        type="submit"
                        className="register-form-btn"
                        value={"Submit"}
                      ></input>
                    )}
                  </div>
                  <p class="upload-doc-info">
                    Note :{" "}
                    <span className="highlight-red-txt">
                      Please select total file count less than 15, each file
                      size less than 8 Mb and only PDF, image file accepted
                    </span>{" "}
                    .
                  </p>
                </form>
              </>
            )}
          </div>
        </div>

        {showMainPopup && (
          <ClaimExistModal
            DataLoading={DataLoading}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeDocPopup}
            claimExistMsg={submissionMsg}
            showMainPopup={showMainPopup}
            setDocListView={setDocListView}
            is2docSubmit={is2docSubmit}
            isMainError={isMainError}
          />
        )}
        {isActiveFullLoader && <FullLoader />}
        <SaveDocmentPopup
          handleDeleteDoc={handleMainSubmission}
          SaveDocPopup={SaveDocPopup}
          handleCloseDocPopUp={handleCloseDocPopUp}
        />
        <DeletePopup
          id={docId}
          handleDeleteDoc={handleDeleteDoc}
          isDeleteModalOpen={isDeleteModalOpen}
          openDeletePopup={openDeletePopup}
          closeDeletePopup={closeDeletePopup}
        />

        <ToastContainer
    position="top-center"
/>
      </div>
    </>
  );
};

export default Claim_document_form;
