import React from "react";
import { Modal } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { claim_info } from "../Assets";
import { FiCheckCircle } from "react-icons/fi";



const SaveDocmentPopup = ({handleDeleteDoc ,SaveDocPopup , openDeletePopup ,handleCloseDocPopUp }) => {


  return (
    <>
      <div className="compare-error-main-cover">
        <Modal
          show={SaveDocPopup} 
          onHide={handleCloseDocPopUp}
          backdrop="static"
          keyboard={false}
          className="modal-compare-cover-box"
          centered
        >
          <Modal.Body className="modal-add-to-compare-main-cover">
            <div className="close-compare-modal">
              <IoIosCloseCircle onClick={handleCloseDocPopUp} />
            </div>
            <div className="claim_exist_modal_cover-wrapper">
                <div className="claim_exist-icon_cover">
                    <img src={claim_info} alt="claim msg"/>
                </div>
                <h5 className="sav-doc-msg">Do you want to submit your Claim ?</h5>
            </div>
            <div className="delete-modal-btns d-flex justify-content-center mt-4">
              <button className="yes-btn" onClick={() =>handleDeleteDoc()}>
              <span className="delete-btn-icon"><FiCheckCircle/></span>
              Yes</button>
              <button onClick={handleCloseDocPopUp}>
              <span className="delete-btn-icon"><IoIosCloseCircle/></span>
              NO</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SaveDocmentPopup;
