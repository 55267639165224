import React from 'react'
import Lottie from "lottie-react";
import loaderAnimation from '../AnimationJson/delete-loader.json'

const LoadingData = () => {
  return (
    <>
    <Lottie animationData={loaderAnimation} className='loadingData-animation'/>
    </>
  )
}

export default LoadingData