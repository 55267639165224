import React from 'react'
import ContentLoader from 'react-content-loader'

const TabLoader = () => {
  return (
        <ContentLoader viewBox="0 0 500 280" height={280} width={500}>
            <circle cx="130" cy="50" r="30" />
            <circle cx="230" cy="50" r="30" />
            <circle cx="330" cy="50" r="30" />
        </ContentLoader>
  )
}

export default TabLoader
