import PolicyTypeTab from "./PolicyTypeTab";
import { useContext } from "react";
import { AppContext } from "../ContextApi";
import TabLoader from "./TabLoader";

const HeaderTestTabs = () => {
  const {
    istabLoading ,tabapiData
  } = useContext(AppContext);

  return (
    <>
      <div className="policy-type-tab-main-cover">
        {istabLoading ? (
          <div className="tab_loadermain-wrapper">
            <TabLoader />
          </div>
        ) : (
          tabapiData.map((tabdata, ind) => {
            return (
                <PolicyTypeTab
                  key={ind}
                  Tabicon={tabdata.logo}
                  fullName={tabdata.full_name}
                  policytypename={tabdata.policy_type_name}
                  policy_id={tabdata.policy_id}
                  policy_type_id={tabdata.policy_type_id}
                  intimateVisibility = {tabdata.intimate_claim_visibility}
                  isTabDisabled={tabdata.policy_type_id === 1 ||
                                tabdata.policy_type_id === 4 ||
                                tabdata.policy_type_id === 5}
                  
                />
              );
          })
        )}
      </div>
    </>
  );
};

export default HeaderTestTabs;
