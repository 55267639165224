import Modal from "react-bootstrap/Modal";

function ClaimModal(props) {
  const {claimModalData} = props
  
  return (
    <div className="claim_modal_main_wrapper">
      <Modal {...props} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton className="claim_status_modal_header">
        </Modal.Header>
        <Modal.Body className="claim_status_modal-content">
          <div className="Track_your_claim_main_wrapper">
            <div className="Track_your_claim_header">
               <h4>Track Your Claim Status</h4>
            </div>
            <div className="Track_your_claim_details_box">
                <div className="row">

                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Name of Insured</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{claimModalData?.insured_name !== "" && claimModalData?.insured_name !== null? claimModalData?.insured_name : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Patient Name</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{claimModalData?.patient_name !== "" && claimModalData?.patient_name !== null? claimModalData?.patient_name : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Hospital Name</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{claimModalData?.hospital_name !== "" && claimModalData?.hospital_name !== null? claimModalData?.hospital_name : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Relation</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{claimModalData?.relation !== "" && claimModalData?.relation !== null? claimModalData?.relation : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Date of Admission</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{claimModalData?.date_of_hospitalization !== "" && claimModalData?.date_of_hospitalization !== null? claimModalData?.date_of_hospitalization : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Date of Discharge</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                      <h5>{claimModalData?.date_of_discharge !== "" && claimModalData?.date_of_discharge !== null ? claimModalData?.date_of_discharge : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Number</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.insurance_claim_no !== ""  && claimModalData?.insurance_claim_no !== null? claimModalData?.insurance_claim_no : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Status</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.status !== "" && claimModalData?.status !== null ? claimModalData?.status : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Outstanding claim status</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.outstanding_claim_status !== "" && claimModalData?.outstanding_claim_status !== null  ? claimModalData?.outstanding_claim_status : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Type</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.claim_type !== "" && claimModalData?.claim_type !== null ? claimModalData?.claim_type : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Network Status</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.network_status !== "" && claimModalData?.network_status !== null ? claimModalData?.network_status : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Registered data</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.claim_registered_date !== "" && claimModalData?.claim_registered_date !== null ? claimModalData?.claim_registered_date : "-"}</h5>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Disease Category</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.disease_category !== "" && claimModalData?.disease_category !== null? claimModalData?.disease_category : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>File recieved Date</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.file_received_date !== "" && claimModalData?.file_received_date !== null ? claimModalData?.file_received_date : "-"}</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Claim Amount</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.claim_amount !== "" && claimModalData?.claim_amount !== null ? claimModalData?.claim_amount : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Deduction Amount</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.deduction_amount !== "" && claimModalData?.deduction_amount !== null ? claimModalData?.deduction_amount : "-"}</h5>

                    </div>
                  </div>
                  
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Deduction Reason</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.deduction_reason !== "" && claimModalData?.deduction_reason !== null ? claimModalData?.deduction_reason : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Amount Claimed</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.amount_claimed !== "" && claimModalData?.amount_claimed !== null ? claimModalData?.amount_claimed : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Amount Paid Date</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.amount_paid_date !== "" && claimModalData?.amount_paid_date !== null ? claimModalData?.amount_paid_date : "-"}</h5>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_title">
                      <h5>Reason For closure</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="claim_data_point">
                    <h5>{claimModalData?.reason_for_closure !== "" && claimModalData?.reason_for_closure !== null ? claimModalData?.reason_for_closure : "-"}</h5>
                    </div>
                  </div>

                </div>
                
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ClaimModal;
