import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import HeaderTestTabs from "../components/HeaderTabsUnique";
import { AppContext } from "../ContextApi";
import Pagination from "../components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import Header from "../components/Header";
import HospitalLoader from "../components/HospitalLoader";
import NoDataFound from "../components/NoDataFound";
import { NotokenFound , validateNumberInput } from "../Utils";
import Accordion from "react-bootstrap/Accordion";
import ClaimsFeatures from "../components/ClaimsFeatures.js";
import Wellness_Benefits from "./Wellness_Benefits.js";
import { mainAPikey } from "../ContextApi";
import { clearFilterIcon } from "../Assets/index";
import { IoReturnUpBackOutline } from "react-icons/io5";


import {
  hospital_banner_img,
  searchicon,
  hospital_not_found,
  network_hospital_icon,
  call_icon,
  location_icon,
  mail_icon,
  hospital_new_banner,
} from "../Assets/index";

const Networkhospital = () => {
  const {
    currentPage,
    setCurrentPage,
    activeElementId,
    requestOptions,
    setRequestOptions,
  } = useContext(AppContext);

  const [network_2_page, setnetwork_2_page] = useState(false);
  const [formData, setFormData] = useState({
    selectedState: "",
    selectedCity: "",
    hospitalName: "",
    pincode: "",
  });
  const [isdataLoading, setisDataLoading] = useState(true);
  const [hospitalmainData, setHospitalmainData] = useState([]);
  const [pagelinks, setpageLinks] = useState([]);
  const [activepagination, setactivepagination] = useState(false);
  const [nextPageUrl, setnextPageUrl] = useState(false);
  const [prevPageUrl, setprevPageUrl] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [openItem, setOpenItem] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [firstGroup, setfirstGroup] = useState(null);
  const [secondGroupData, setsecondGroupData] = useState(null);
  const [itemsPerGroup, setitemsPerGroup] = useState(null);
  const navigate = useNavigate();
  const [lastApiStatus, setLastApiStatus] = useState(null);
  const [UnAuthorized, setUnAuthorized] = useState(false);
  const [appLinkToken, setappLinkToken] = useState(
    localStorage.getItem("appLinkToken")
  );

  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  async function handleTokenInResponse(response, token, isLastApiCall) {
    if (response.status === 401 || token === "") {
      if (isLastApiCall) {
        console.log("get-state is UnAuthorized");
        // NotokenFound(navigate);
        setUnAuthorized(true);
      }
      return response;
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        localStorage.setItem("authorizationToken", authorizationToken);
        setUnAuthorized(false);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(authorizationToken);
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        return updatedResponse;
      } else {
        // console.log(response)
        // console.log(requestOptions.headers['Authorization'] === token)
        return response;
      }
    }
  }

  useEffect(() => {
    setactivepagination(false);

    async function fetchData() {
      setisDataLoading(false);
      try {
        const stateResponse = await fetchState(token);
        if (stateResponse === 401) {
          throw Error("Unauthorize");
        } else {
          const hospitalResponse = await fetchHospital();
          setLastApiStatus(hospitalResponse.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchData();
  }, [activeElementId, currentPage, token]);

  useEffect(() => {
    setnetwork_2_page(false);
  }, [activeElementId]);

  // console.log(requestOptions)

  async function fetchState(token) {
    if (token && activeElementId) {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/${mainAPikey}` +
          `/employee/get-state`,
        requestOptions
      );
      const tokenResponse = await handleTokenInResponse(response, token, true);
      const res = await tokenResponse.json();

      if (res.success) {
        setStateOptions(
          res.data?.map((state) => ({
            value: state.trim(),
            label: state,
          }))
        );
        return response; // Return the response
      } else {
        throw new Error(res.message);
      }
    }
  }

  async function fetchHospital() {
    setisDataLoading(true);
    if (activeElementId && token) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/${mainAPikey}` +
            `/employee/hospital-list?policy_id=${activeElementId}&state=${formData.selectedState}&city=${formData?.selectedCity}&hospital_name=${formData?.hospitalName}&page=${currentPage}&pincode=${formData?.pincode}`,
          requestOptions
        );
        const tokenResponse = await handleTokenInResponse(
          response,
          token,
          false
        );
        if (!tokenResponse.ok) {
          // NotokenFound(navigate)
        }
        setLastApiStatus(tokenResponse.status);
        const res = await tokenResponse.json();

        if (res.success) {
          setpageLinks(res?.data?.links);
          setHospitalmainData(res?.data?.data);
          setisDataLoading(false);
          setnextPageUrl(res?.data?.next_page_url);
          setprevPageUrl(res?.data?.prev_page_url);
          setactivepagination(true);
        } else {
          setisDataLoading(false);
          setHospitalmainData(res?.data?.data);
        }
      } catch (error) {
        setisDataLoading(false);
        setHospitalmainData([]);
        console.error(error);
      }
    }
  }

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  useEffect(() => {
    if (lastApiStatus === 401 && UnAuthorized) {
      NotokenFound(navigate);
    } else if (lastApiStatus !== null) {
      if (lastApiStatus === 200) {
        console.log("Authorized");
      }
    }
  }, [lastApiStatus]);

  const handlesearch = () => {
    setisDataLoading(true);
    setnetwork_2_page(true);
    setCurrentPage(1);
    setactivepagination(false);
    fetchHospital();
  };

  useEffect(() => {
    async function fetchCity(state) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/${mainAPikey}` +
            `/employee/get-city-by-state?state=${formData.selectedState}`,
          requestOptions
        );
        const tokenResponse = await handleTokenInResponse(response, token);
        // console.log(tokenResponse)
        setLastApiStatus(tokenResponse.status);
        const res = await tokenResponse.json();
        if (res.success) {
          setCityOptions(
            res?.data?.map((city) => ({
              value: city.trim(),
              label: city,
            }))
          );
          // setStateDataMsg(res.message)
        } else {
          setisDataLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (formData.selectedState !== "") {
      fetchCity(formData.selectedState);
    }
  }, [formData.selectedState, token]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    if (hospitalmainData) {
      setitemsPerGroup(Math.ceil(hospitalmainData.length / 2));
      setfirstGroup(hospitalmainData?.slice(0, itemsPerGroup));
      setsecondGroupData(
        hospitalmainData?.slice(itemsPerGroup, hospitalmainData?.length)
      );
    }
  }, [hospitalmainData, itemsPerGroup]);

  const handleAccordionClick = (eventKey) => {
    if (openItem === eventKey) {
      setOpenItem(null);
    } else {
      setOpenItem(eventKey);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "15px",
      border: "0.5px solid #70d3ff",
      padding: "6px 10px",
      width: "300px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#36a2eb" : "white",
      color: state.isSelected ? "white" : "black",
      borderRadius: "10px",
    }),
  };

  const smallScreenStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "15px",
      border: "0.5px solid #70d3ff",
      padding: "6px 10px",
    }),
  };

  const clearFields = () => {
    setFormData({
      selectedState: "",
      selectedCity: "",
      hospitalName: "",
      pincode: "",
    })
  };

  const handleHospitalData = (e) => {
    const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  }

  const handleSelectChange = (selectedOption) => {
    setnetwork_2_page(false);

    if (selectedOption) {
      setFormData((prevState) => ({
        ...prevState,
          selectedState: selectedOption.value,
          selectedCity: "",
          pincode: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        selectedState: "",
      }));
    }
  };

  const handleCityChange = (selectedOption) => {
    setFormData(prevState => ({
        ...prevState,
        selectedCity: selectedOption ? selectedOption.value : "",
    }));
};

  const selectedCityOption = formData.selectedCity
    ? { value: formData.selectedCity, label: formData.selectedCity }
    : null;

  const responsiveStyles =
    window.innerWidth < 768
      ? { ...customStyles, ...smallScreenStyles }
      : customStyles;

  const hasNonEmptyValue = Object.values(formData).some(value => value.trim() !== "");


  return (
    <>
      <Header />
      <div className="network-hospital-main-cover-wrapper">
        <div className="claims-tabs-section-cover-wrapper">
          <div className="row">
            <div className="col-lg-4">
              <div
                className={`sticky-element ${isSticky ? "sticky-form" : ""}`}
              >
                <HeaderTestTabs />
                <div className="separater"></div>
                <ClaimsFeatures />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="claims-content-cover-wrapper">
                {!network_2_page && (
                  <div className="claims-first-page">
                    <div className="col-lg-12">
                      <div className="main-banner-box">
                        <img
                          className="hospital-banner-img"
                          src={hospital_banner_img}
                          alt="Hospital Banner"
                        />
                      </div>
                    </div>
                    <div className={`sticky-element`}>
                      <div className="gmc-main-box-content">
                        <div className="network-hospital-form-content">
                          <div className="hospital-text mb-lg-4">
                            {/* <label htmlFor>Hospital Name</label> */}
                            <input
                              type="text"
                              name="hospitalName"
                              onChange={handleHospitalData}
                              placeholder="Enter hospital name"
                              value={formData.hospitalName}
                            />
                            <img src={searchicon} alt="search-icon" />
                          </div>
                          <div className="hospital-text">
                            {/* <label htmlFor>Pincode</label> */}
                            <input
                              type="text"
                              name="pincode"
                              // onChange={(e) => setPincode(e.target.value)}
                              onInput={(e) =>validateNumberInput(e)}
                              placeholder="Enter Pincode"
                              maxLength={6}
                              value={formData.pincode}
                              onChange={handleHospitalData}
                            />
                            <img src={searchicon} alt="search-icon" />
                          </div>
                        </div>
                        <div className="network-hospital-option-content">
                          <div className="hospital-option state">
                            <Select
                              className="mb-3"
                              options={stateOptions}
                              isSearchable={true}
                              styles={responsiveStyles}
                              placeholder="Select State"
                              value={
                                formData.selectedState
                                  ? {
                                      value: formData.selectedState,
                                      label: formData.selectedState,
                                    }
                                  : null
                              }
                              onChange={handleSelectChange}
                            />
                          </div>
                          <div className="error-msg-state">
                            <p>Please Select State</p>
                          </div>
                          <div className="hospital-option city">
                            <Select
                              className="mb-3"
                              options={cityOptions}
                              isSearchable={true}
                              placeholder="Select City"
                              styles={responsiveStyles}
                              value={selectedCityOption}
                              onChange={handleCityChange}
                            />
                          </div>
                          <div className="error-msg-state">
                            <p>Please Select City</p>
                          </div>
                        </div>
                      </div>
                      <div className="network-hospital-search">
                        
                        <button
                          type="submit"
                          className={!hasNonEmptyValue ? "disable-btn hospital-search-btn mx-3" : "hospital-search-btn mx-3" }
                          onClick={() => handlesearch()}
                          disabled={!hasNonEmptyValue}
                        >
                          <img src={searchicon} alt="search" />
                          <span>Search</span>
                        </button>

                        {(formData.selectedState !== "" ||
                          formData.selectedCity !== "" ||
                          formData.hospitalName !== "" ||
                          formData.pincode !== "") && (
                          <button
                            className="clear-filter-btn"
                            onClick={() => clearFields()}
                          >
                            <span>
                              <img
                                src={clearFilterIcon}
                                alt="Clear Filter Icon"
                              />
                            </span>
                            Clear Filter
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {network_2_page ? (
                  hospitalmainData?.length > 0 ? (
                    <div className="col-lg-12">
                      <div className="network-hospital-main-cover-content">
                        <div className="row d-flex justify-content-center">
                          <div className="col-lg-12">
                            <div
                              className="back-btn-box"
                              style={{ display: "block" }}
                            ></div>
                            <div className="network-hospital-heading">
                              <button
                                className="backtoform"
                                onClick={() => setnetwork_2_page(false)}
                              >
                                <span className="mx-3">
                                  <IoReturnUpBackOutline />
                                </span>
                                Back
                              </button>
                              <h3 className="text-center">Network Hospital</h3>
                              <div className="network-hospital-box-content">
                                <p>
                                  ( These hospitals provide cashless facility
                                  for this policy )
                                </p>
                              </div>
                            </div>
                            {isdataLoading ? (
                              <div className="row mt-4">
                                <div className="col-lg-6 mb-3">
                                  <HospitalLoader />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <HospitalLoader />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <HospitalLoader />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <HospitalLoader />
                                </div>
                              </div>
                            ) : (
                              <div className="row d-flex justify-content-center">
                                {hospitalmainData.length === 0 ? (
                                  <NoDataFound />
                                ) : (
                                  <div className="hospital-accordion-main-wrapper">
                                    <Accordion defaultActiveKey={openItem}>
                                      <div className="row">
                                        {firstGroup && (
                                          <div className="col-lg-6">
                                            {firstGroup.map(
                                              (hospitalList, index) => {
                                                const eventkey = index;
                                                return (
                                                  <div className="col-lg-12">
                                                    <div className="hospital-accordion-main-box">
                                                      <Accordion.Item
                                                        eventKey={eventkey}
                                                        onClick={() =>
                                                          handleAccordionClick(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <Accordion.Header>
                                                          <div className="box-image">
                                                            <img
                                                              src={
                                                                network_hospital_icon
                                                              }
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div className="box-heading">
                                                            <h4>
                                                              {hospitalList?.hospital_name ||
                                                                ""}
                                                            </h4>
                                                          </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                          <div className="hospital-info-content">
                                                            {hospitalList.phone && (
                                                              <div className="hospital-phone">
                                                                <div className="hospital-location-image">
                                                                  <img
                                                                    src={
                                                                      call_icon
                                                                    }
                                                                    alt=""
                                                                  />
                                                                </div>
                                                                <div className="hospital-location-info">
                                                                  <p>
                                                                    {
                                                                      hospitalList.phone
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            )}

                                                            <div className="hospital-location">
                                                              {hospitalList.hospital_address && (
                                                                <div className="hospital-location-container">
                                                                  <div className="hospital-location-image">
                                                                    <img
                                                                      src={
                                                                        location_icon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  </div>
                                                                  <div className="hospital-location-info">
                                                                    <p>
                                                                      {
                                                                        hospitalList.hospital_address
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                            {hospitalList?.email && (
                                                              <div className="hospital-mail">
                                                                <div className="hospital-location-image">
                                                                  <img
                                                                    src={
                                                                      mail_icon
                                                                    }
                                                                    alt
                                                                  />
                                                                </div>
                                                                <div className="hospital-location-info">
                                                                  <p>
                                                                    {
                                                                      hospitalList?.email
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        )}
                                        {secondGroupData && (
                                          <div className="col-lg-6">
                                            {secondGroupData.map(
                                              (hospitalList, index) => {
                                                const eventkey =
                                                  index +
                                                  1 +
                                                  firstGroup?.length;
                                                return (
                                                  <div className="col-lg-12">
                                                    <div className="hospital-accordion-main-box">
                                                      <Accordion.Item
                                                        eventKey={eventkey}
                                                        onClick={() =>
                                                          handleAccordionClick(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <Accordion.Header>
                                                          <div className="box-image">
                                                            <img
                                                              src={
                                                                network_hospital_icon
                                                              }
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div className="box-heading">
                                                            <h4>
                                                              {hospitalList?.hospital_name ||
                                                                ""}
                                                            </h4>
                                                          </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                          <div className="hospital-info-content">
                                                            {hospitalList.phone && (
                                                              <div className="hospital-phone">
                                                                <div className="hospital-location-image">
                                                                  <img
                                                                    src={
                                                                      call_icon
                                                                    }
                                                                    alt=""
                                                                  />
                                                                </div>
                                                                <div className="hospital-location-info">
                                                                  <p>
                                                                    {
                                                                      hospitalList.phone
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            )}

                                                            <div className="hospital-location">
                                                              {hospitalList.hospital_address && (
                                                                <div className="hospital-location-container">
                                                                  <div className="hospital-location-image">
                                                                    <img
                                                                      src={
                                                                        location_icon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  </div>
                                                                  <div className="hospital-location-info">
                                                                    <p>
                                                                      {
                                                                        hospitalList.hospital_address
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Accordion>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {activepagination && pagelinks.length > 0 && (
                        <div className="pagination-main-wrapper">
                          <Pagination
                            LinkData={pagelinks}
                            setisDataLoading={setisDataLoading}
                            nextPageUrl={nextPageUrl}
                            prevPageUrl={prevPageUrl}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-lg-8">
                      <button
                        className="backtoform"
                        onClick={() => setnetwork_2_page(false)}
                      >
                        <span className="mx-3">
                          <IoReturnUpBackOutline />
                        </span>
                        Back
                      </button>
                      <div className="hospital-not-found">
                        <img
                          src={hospital_not_found}
                          alt="Hospital Not Found"
                        />
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
      <Wellness_Benefits appLinkToken={appLinkToken} />
    </>
  );
};

export default Networkhospital;
