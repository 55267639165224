// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import successJson from "../AnimationJson/success.json"

const SuccessFull = () => {
    return (
        <>
            <div className='success-main-cover'>
                <Lottie animationData={successJson } className='success-data-animation' />
            </div>
        </>
    )
}

export default SuccessFull;
