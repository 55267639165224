import { useState, useEffect ,useContext } from "react";
import Header from "../components/Header";
import Leave_form_modal from "../components/Leave_form_Modal";
import DataTable from "react-data-table-component";
import {searchicon} from "../Assets/index";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import { AppContext } from "../ContextApi";
import { ToastContainer } from "react-toastify";

const Leaves_employee = () => {
  const [show, setShow] = useState(false);
  const [LeaveData, setLeaveData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [lastApiStatus, setLastApiStatus] = useState(null);
  const [UnAuthorized , setUnAuthorized] = useState(false)
  const [isSearched , setisSearched] = useState(false)
  const { requestOptions ,setRequestOptions ,token , setToken} = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalApprovedleave , setTotalApprovedLeaves] = useState(0)
  const [totalleave , setTotalLeaves] = useState(0)
  const [totalPendingleave , setTotalPendingLeaves] = useState(0)
  const [totalRejectedleave , setTotalRejectedLeaves] = useState(0)
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  async  function handleTokenInResponse(response , isLastApiCall) {
    if(response.status === 401 || token === "" ){
      if (isLastApiCall) {
        console.log("get-state is UnAuthorized")
        // NotokenFound(navigate);
        setUnAuthorized(true)
      }
      return response
    }
    else{
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        // console.log(authorizationToken)
        localStorage.setItem("authorizationToken", authorizationToken);
        requestOptions.headers.Authorization = authorizationToken
        setToken(authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });    
        return updatedResponse;
      } else {
        return response
      }
    }
  }

  const GetData = async () => {
    requestOptions.method = "GET";
    requestOptions.body = null ;
    requestOptions.headers.Authorization = localStorage.getItem("authorizationToken");
    try {
      const response = await fetch( process.env.REACT_APP_API_URL +"/employee/leave-application-list" ,requestOptions);
      const tokenResponse = await handleTokenInResponse(response);
      const res = await tokenResponse.json();
      setLeaveData(res.data.leaves_list);
      setFilterData(res.data.leaves_list);
      setTotalApprovedLeaves(res.data.total_approved)
      setTotalLeaves(res.data.total_leave)
      setTotalPendingLeaves(res.data.total_pending)
      setTotalRejectedLeaves(res.data.total_rejected)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const HandleSearchinput = (e) =>{
    setSearchQuery(e.target.value)
    setFilterData(LeaveData)
}

const handleSearch = () => {
  if (searchQuery === "") {
    setFilterData(LeaveData);
  } else {
    const result = searchQuery.trim() === '' ? LeaveData : LeaveData.filter((item) => {
      const values = Object.values(item).map((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase();
        } else {
          return String(value).toLowerCase();
        }
      });
      const includesFilter = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      
      return includesFilter;
    });
    setFilterData(result);
  }
  setisSearched(true);
};


const handleClose = () => setShow(false);

  const columns = [
    {
      name: "Sr No",
      selector: (row , index) => index + 1,
    },
    {
      name: "Start Date",
      selector: (row) => row.leave_start_date,
    },
    {
      name: "End Date",
      selector: (row) => row.leave_end_date,
    },
    {
      name: "No of Days",
      selector: (row) => row.total_days,
    },
    {
      name: "Leave Reason",
      selector: (row) => row.leave_reason,
    },
    {
      name: "Status",
      cell: (row) => {
        const statusLabels = {
          0: 'Pending',
          1: 'Approved',
          2: 'Rejected',
        };
        
        return statusLabels[row.leave_Status] || '-';
      },
    },
    {
      name: "Approved by",
      cell: (row) => {
        if (row.leave_updated_by) {
          return row.leave_updated_by;
        }
        return '-';
      },
    },
    {
      name: "Rejected Reason",
      cell: (row) => {
        if (row.rejected_reason) {
          return row.rejected_reason;
        }
        return '-';
      },
    },
  ];
  
  const RowStyles = {
    rows: {
      style: {
        "&:nth-child(even)": {
          backgroundColor: "#f7f7f7",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#ffffff",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        borderTop: "1px solid #f7f7f7",
      },
    },
  };

  useEffect(() => {
    if(UnAuthorized){
      NotokenFound(navigate);
    }
    GetData();
  }, [token]);

  useEffect(() =>{
    if(!token ){
      NotokenFound(navigate)
    }
  },[])

  return (
    <>
      <Header />
      <div className="leaves-main-wrapper-cover">
        <div className="container">
          <div className="leaves-employee-header-cover">
            <div className="leaves-employee-header d-flex justify-content-between">
              <div className="leaves-employee-title">
                <h3>Leave</h3>
              </div>
              <button
                type="button"
                className="AddnewDocbutton AddnewLeavebutton"
                onClick={toggleModal}
              >
                <span className="button__text">Apply Leave</span>
                <span className="button__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    stroke="currentColor"
                    height={24}
                    fill="none"
                    className="svg"
                  >
                    <line y2={19} y1={5} x2={12} x1={12} />
                    <line y2={12} y1={12} x2={19} x1={5} />
                  </svg>
                </span>
              </button>
            </div>
            <div className="leaves-employee-tab-box-cover">
              <div className="row">
                <div className="col-lg-3">
                  <div className="leaves-employee-count-box">
                    <h5 className="text-center">Approved Leaves</h5>
                    <h3 className="text-center">{totalApprovedleave > 0 ? totalApprovedleave : 0 }</h3>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="leaves-employee-count-box">
                    <h5 className="text-center">Rejected Leaves</h5>
                    <h3 className="text-center">{totalRejectedleave > 0 ? totalRejectedleave : 0}</h3>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="leaves-employee-count-box">
                    <h5 className="text-center">Pending Leaves</h5>
                    <h3 className="text-center">{totalPendingleave > 0 ? totalPendingleave : 0}</h3>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="leaves-employee-count-box">
                    <h5 className="text-center">Total Requested</h5>
                    <h3 className="text-center">{totalleave > 0 ? totalleave : 0}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="attendence-employee-data-cover">
            {LeaveData && (
              <DataTable
              columns={columns}
              data={searchQuery && isSearched ? filterData : LeaveData}
              pagination
              className="custom-datatable"
              customStyles={RowStyles}
              subHeader
              subHeaderComponent={
                <div className="datatable-header-component">
                  <div className="search-input-box">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => HandleSearchinput(e)}
                    />
                  </div>
                  <div className="search-btn-box">
                    <button onClick={handleSearch}><span className="search-icon"> <img src={searchicon} /></span> Search</button>
                  </div>
                </div>
              }
            />
            )}
            
          </div>
        </div>
      </div>
      <Leave_form_modal
        handleClose={handleClose}
        onHide={() => setShow(false)}
        GetData ={GetData}
        show={isModalOpen} 
        setShow={toggleModal}
        requestOptions={requestOptions}
        setRequestOptions={setRequestOptions}
        token={token}
        setLastApiStatus={setLastApiStatus}
        setToken={setToken}
      />
      <ToastContainer
    position="top-center"
/>
    </>
  );
};

export default Leaves_employee;
