import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../ContextApi";
import {
  Network_hospital_icon,
  MemberCovered_icon,
  My_Claims_icon,
  Coverage_icon,
  Policy_Detail_icon,
  Download_form_icon,
  Claim_status_icon,
  wellness_icon,
  Hrms_Icon,
  Application_leave_icon,
  Attendence_icon,
  circular_icon,
  dummy_logo,
  Main_logo,
  demo_logo,
} from "../Assets/index";

const ClaimsFeatures = () => {
  const { featuresType , intimateArray  , claimSubmissionVisiblity ,tabapiData } = useContext(AppContext);

  useEffect(() => {
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const path = parsedUrl.pathname;
    const anchorTags = document.querySelectorAll(".nav-links");
    anchorTags.forEach((anchor) => {
      if (anchor.getAttribute("href") == path) {
        anchor.classList.add("activeNav-link");
      }
    });
  }, []);

  return (
    <>
      {
        featuresType !== "" && featuresType === "GMC" ||  featuresType == "Top Up Policy" || featuresType == "Parent Policy" ? (
          <div className="claims-features-main-cover-wrapper">
            <Link className="nav-links" to="/policy-details">
              <span className="nav-icon-box">
                <img src={Policy_Detail_icon}></img>
              </span>
              Policy Details
            </Link>
            <Link className="nav-links" to="/mycoverage">
              <span className="nav-icon-box">
                <img src={Coverage_icon}></img>
              </span>
              My Coverages
            </Link>
            <Link className="nav-links" to="/members-covered">
              <span className="nav-icon-box">
                <img src={MemberCovered_icon}></img>
              </span>
              Members Covered
            </Link>
            <Link className="nav-links" to="/escalation-matrix">
              <span className="nav-icon-box">
                <img src={MemberCovered_icon}></img>
              </span>
              Escalation Matrix
            </Link>
            <Link className="nav-links" to="/network-hospital">
              <span className="nav-icon-box">
                <img src={Network_hospital_icon}></img>
              </span>
              Network Hospital
            </Link>
            <Link className="nav-links" to="/download-form">
              <span className="nav-icon-box">
                <img src={Download_form_icon}></img>
              </span>
              Download Forms
            </Link>
            {tabapiData?.find((dumData) => dumData.policy_type_name == featuresType)?.intimate_claim_visibility == 1 && (
              <Link className="nav-links" to="/intimate-myclaims">
                <span className="nav-icon-box">
                  <img src={My_Claims_icon}></img>
                </span>
                Intimate my Claim
              </Link>
            )}
            
            
            <Link className="nav-links" to="/claim-status">
              <span className="nav-icon-box">
                <img src={Claim_status_icon}></img>
              </span>
              Claim Status
            </Link>


            {tabapiData?.find((dumData) => dumData.policy_type_name == featuresType)?.claim_submission_visibility == 1 && (
              <Link className="nav-links" to="/claim-submission">
                    <span className="nav-icon-box">
                      <img src={MemberCovered_icon}></img>
                    </span>
                    Claim Submission
                  </Link>
                )}

            {/* <Link className="nav-links" to="/wellness-corner">
              <span className="nav-icon-box">
                <img src={wellness_icon}></img>
              </span>
              Wellness Corner
            </Link> */}
          </div>
        ) : featuresType === "GPA" || featuresType === "GTL" ? (
          <div className="claims-features-main-cover-wrapper">
            <Link className="nav-links" to="/policy-details">
              <span className="nav-icon-box">
                <img src={Policy_Detail_icon}></img>
              </span>
              Policy Details
            </Link>
            <Link className="nav-links" to="/mycoverage">
              <span className="nav-icon-box">
                <img src={Coverage_icon}></img>
              </span>
              My Coverages
            </Link>
            <Link className="nav-links" to="/members-covered">
              <span className="nav-icon-box">
                <img src={MemberCovered_icon}></img>
              </span>
              Members Covered
            </Link>
            {tabapiData?.find((dumData) => dumData.policy_type_name == featuresType)?.intimate_claim_visibility == 1 && (
              <Link className="nav-links" to="/intimate-myclaims">
                <span className="nav-icon-box">
                  <img src={My_Claims_icon}></img>
                </span>
                Intimate my Claim
              </Link>
            )}
            {tabapiData?.find((dumData) => dumData.policy_type_name == featuresType)?.claim_submission_visibility == 1 && (
            <Link className="nav-links" to="/claim-submission">
                  <span className="nav-icon-box">
                    <img src={MemberCovered_icon}></img>
                  </span>
                  Claim Submission
                </Link>
              )}
          </div>
        ) : null /* If featuresType is none of the above, render nothing */
      }
    </>
  );
};

export default ClaimsFeatures;
