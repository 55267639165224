import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { DemoCorporate , Claims_banner_img } from "../Assets";

import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";

const HeroSection = () => {

  return (
    <>
      <div className="hero-section-main-cover-wrapper">
        <div className="hero-content-box-cover">
          <div className="container">
            <div className="hero-content-cover">
              <div className="row d-flex justify-content-between">
                <div className="col-lg-6">
                  <div className="hero-title-typography-content">
                    <h2>
                      <span className="highlight-txt">
                        Unlock Your Potential:
                      </span>{" "}
                      Discover Our Exclusive Employee Benefits
                    </h2>
                    {/* <p>
                      Boost your well-being and career with our standout employee
                      benefits: competitive healthcare, flexible work options,
                      professional development, and more. Your success and
                      happiness are our priorities – join us and thrive
                    </p> */}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="hero-banner-cover">
                      <img src={Claims_banner_img} alt="banner-img"></img>
                    </div>
                </div>
                {/* <div className="col-lg-5">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }} 
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <div className="hero-banner-cover">
                        <img src={DemoCorporate} alt="banner-img"></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="hero-banner-cover">
                        <img src={DemoCorporate} alt="banner-img"></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="hero-banner-cover">
                        <img src={DemoCorporate} alt="banner-img"></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="hero-banner-cover">
                        <img src={DemoCorporate} alt="banner-img"></img>
                      </div>
                    </SwiperSlide>
                    

                  
                  </Swiper>
                </div> */}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default HeroSection;
