import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import { AppContext } from "../ContextApi";
import { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { NotokenFound, formatDateToYYYYMMDD } from "../Utils";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

function Leave_form_modal(props) {
  const {
    GetData,
    show,
    setShow,
    requestOptions,
    token,
    setToken,
    setRequestOptions,
  } = props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [SelectedFile, setSelectedFile] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [Errorfields, setErrorfeilds] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [leaveType , setLeaveType] = useState(null)

  const { register, handleSubmit } = useForm();

  async function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setToken(authorizationToken);
        requestOptions.headers.Authorization = authorizationToken;
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);
  

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("attachment", data.attachment[0]);
    formData.append("start_date", startDate !== "" && formatDateToYYYYMMDD(startDate));
    formData.append("end_date", endDate !== "" && formatDateToYYYYMMDD(endDate)  );
    formData.append("leave_reason", leaveReason);
    formData.append("leave_type", leaveType);

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formData;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;
    requestOptionsCopy.headers = {
      Authorization: requestOptions.headers.Authorization,
      // 'Content-Type': 'multipart/formdata',
    };

    async function submitLeaveData() {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/employee/employee-leave`,
          requestOptionsCopy
        );
        const updatedResponse = await handleTokenInResponse(response);
        const res = await updatedResponse.json();
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setStartDate("");
          setEndDate("");
          setLeaveReason("");
          setShow(false);
          GetData();
        } else {
          setErrorfeilds(res.errors_fields);
          console.log(res.errors_fields);
          toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error(error);
      }
    }
    submitLeaveData();
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() =>{
    console.log(leaveType);
  } ,[leaveType])

  return (
    <div className="hospital_modal_main_wrapper">
      <Modal {...props} show={show} backdrop="static" keyboard={false} centered>
        <Modal.Header className="leave_employee_modal">
          <button variant="secondary" onClick={handleClose}>
            x
          </button>
        </Modal.Header>
        <Modal.Body className="leave_employee_modal">
          <div className="leave_employee_form-wrapper">
            <form
              className="leave_employee_form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h4 className="text-center">Add Leave</h4>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <div className="leave_employee_input">
                  <Form.Label>Start Date *</Form.Label>
                  <DatePicker
                    name="start_date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select a Start Date"
                    showYearDropdown={false} // Hide the year dropdown
                    showMonthDropdown={false}
                    autoComplete="off"
                  />
                  {Errorfields.start_date && (
                    <small className="text-danger">
                      {Errorfields.start_date}
                    </small>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <div className="leave_employee_input">
                  <Form.Label>End Date *</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="Select End Date"
                    name="end_date"
                    showYearDropdown={false} // Hide the year dropdown
                    showMonthDropdown={false}
                    autoComplete="off"
                  />
                  {Errorfields.end_date && (
                    <small className="text-danger">
                      {Errorfields.end_date}
                    </small>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlSelect1"
              >
                <div className="leave_employee_input">
                  <Form.Label>Leave Type *</Form.Label>
                  <Form.Select
                    name="leave_type"
                    value={leaveType}
                    onChange={(e) => setLeaveType(e.target.value)}
                  >
                    <option value="">Select Leave Type</option>
                    <option value="0">Full Day</option>
                    <option value="1">Half Day</option>
                  </Form.Select>
                  {Errorfields.leave_type && (
                    <small className="text-danger">
                      {Errorfields.leave_type}
                    </small>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <div className="leave_employee_input">
                  <Form.Label>Select File</Form.Label>
                  {/* <Form.Control
                    type="file"
                    placeholder="Choose File"
                    onChange={(e) => handleFileChange(e)}
                  /> */}
                  <input type="file" {...register("attachment")} />
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <div className="leave_employee_input">
                  <Form.Label>Leave Reason *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="leave_reason"
                    placeholderText="Enter Leave Reason"
                    onChange={(e) => setLeaveReason(e.target.value)}
                  />
                  {Errorfields.leave_reason && (
                    <small className="text-danger">
                      {Errorfields.leave_reason}
                    </small>
                  )}
                </div>
              </Form.Group>

              <div className="submit-btn-box">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Leave_form_modal;
