import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../ContextApi";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { formIcon, login_banner_img , arrowIcon } from "../Assets/index";
import ReCAPTCHA from "react-google-recaptcha";
import { DEFAULT_CAPTCHA_KEY } from "../Constants";
import "../login.css";

const Login = ({setIsAuthenticated}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isMobileVerified, setisMobileVerified] = useState(false);
  const [mobilemssage, setmobilemessage] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [otpMessage, setotpMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [hideotpbtn, setOtpBtn] = useState(true);
  const [showRecaptchabtn, setRecaptchBtn] = useState(true);

  const {
    token,
    setToken,
    requestOptions,
    setRequestOptions,
    isLoggedIn,
    setIsLoggedIn,
    setAuthToken,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const requestOptionsNew = { ...requestOptions };
  requestOptionsNew.method = "POST";

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    setMobileNumber(value);
    setOtpSent(false); //Reseting Otp
  };

  const handleOtpChange = (event) => {
    const value = event.target.value;
    setOtp(value);
  };

  useEffect(() => {
    otp.length === 6 ? setIsOtpValid(true) : setIsOtpValid(false);
  }, [otp]);

  function validateNumberInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  }

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  useEffect(() => {
    if (mobileNumber.length === 10) {
      setRecaptchBtn(true);
      if (captchaToken) {
        setOtpBtn(true);
      }
    }
    setIsOtpValid(false);
    setOtp("");
  }, [mobileNumber]);

  const handlelogin = () => {
    setIsLoading(true);

    if (otp) {
      setIsOtpValid(true);
      fetch(
        process.env.REACT_APP_API_URL +
          `/verify-web-otp?template=login&mobile_number=${mobileNumber}&email_id&otp=${otp}`,
        requestOptionsNew
      )
        .then((response) => {
          const authorizationToken = response.headers.get("Authorization");
          localStorage.setItem("authorizationToken", authorizationToken);
          setIsAuthenticated(authorizationToken)
          setRequestOptions((prevOptions) => ({
            ...prevOptions,
            headers: {
              ...prevOptions.headers,
              Authorization: authorizationToken,
            },
          }));
          setToken(authorizationToken);
          setAuthToken(authorizationToken);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_CENTER, // Customize toast position
              autoClose: 2000, // Close the toast after 3 seconds
            });
            setIsLoading(false);

            setTimeout(() => {
              navigate("/policy-details");
            }, 2000);
          } else {
            setIsLoading(false);
            toast.error(data.message, {
              position: toast.POSITION.TOP_CENTER, // Customize toast position
              autoClose: 3000, // Close the toast after 3 seconds
            });
            setotpMessage(data.message);
          }
          data.success ? setIsVerified(true) : setotpMessage(data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          toast.error("plz enter valid otp", {
            position: toast.POSITION.TOP_CENTER, // Customize toast position
            autoClose: 3000, // Close the toast after 3 seconds
          });
        });
    } else {
      setIsVerified(false);
      setIsOtpValid(false);
    }
  };

  const generateOtp = () => {
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        `/generate-web-otp?template=login&mobile_number=${mobileNumber}&g-recaptcha-response=${captchaToken}&email_id`,
      requestOptionsNew
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOtpSent(true);
          setisMobileVerified(true);
          setmobilemessage(data.message);
          setOtpBtn(false);
          setRecaptchBtn(false);
          setIsLoading(false);
        } else {
          setisMobileVerified(false);
          setmobilemessage(data.message);
          setIsLoading(false);
          recaptchaRef.current.reset();
          setCaptchaToken(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching OTP:", error);
        setIsLoading(false);
        recaptchaRef.current.reset();
        toast.error(
          "Oops! You have reached maximum limit, please try again after an hour."
        );
        setCaptchaToken(null);
      });
  };

  return (
    <>
      <div className="policyera-corporate-login-cover">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-10">
            <div className="left-banner-wrapper">
              <div className="row d-flex">
                <div className="col-lg-2 p-0">
                  <div className="slide-controls-box">
                    <div className="slide-controls">
                      <input
                        type="radio"
                        name="slide"
                        id="login" 
                      />
                      <input type="radio" name="slide" id="signup" defaultChecked/>
                      <label htmlFor="signup" className="slide signup signup-active">
                      
                        LOGIN
                        <span>
                          <img
                            className="arrow-icon"
                            src={arrowIcon}
                            alt="insurer"
                          />
                        </span>
                      </label>
                      <Link to="/register" className="">
                        <label htmlFor="login" className="slide login">
                        
                          REGISTER
                          <span>
                            <img
                              className="arrow-icon"
                              src={arrowIcon}
                              alt="insurer"
                            />
                          </span>
                        </label>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 p-0 ">
                  <div className="choice-banner-cover">
                    <div className="choice-banner-img-box">
                      <img
                        src={login_banner_img}
                        alt="insurer"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-0">
                  <div className="policyera-corporate-login-form">
                    <div className="corporate-console-login-box">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-12">
                          <div className="login-header">
                            <h4>Choice</h4>
                          </div>
                          <div className="login-content">
                            <div className="login-small-icon">
                              <img
                                src={formIcon}
                                alt="insurer"
                              />
                            </div>
                            <div className="login-small-heading">
                              EMPLOYEE REGISTRATION / LOGIN
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="login-form-main-cover-wrapper">
                        <form>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-input-box mb-3">
                                <div className="form-input">
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                  >
                                    Mobile Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    maxLength={10}
                                    value={mobileNumber}
                                    onChange={handleMobileNumberChange}
                                    onInput={(e) => validateNumberInput(e)}
                                    placeholder="Enter mobile number"
                                    required="true"
                                  />
                                </div>
                                {mobileNumber !== "" && (
                                  <small
                                    className={
                                      isMobileVerified
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {" "}
                                    {isMobileVerified
                                      ? mobilemssage
                                      : mobilemssage}
                                  </small>
                                )}
                              </div>
                              {showRecaptchabtn && (
                                <ReCAPTCHA
                                  sitekey={DEFAULT_CAPTCHA_KEY}
                                  onChange={onCaptchaChange}
                                  ref={recaptchaRef}
                                />
                              )}

                              {otpSent && (
                                <div className="form-input-box mb-3">
                                  <div className="form-input">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Enter Otp
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={otp}
                                      onChange={handleOtpChange}
                                      onInput={(e) => validateNumberInput(e)}
                                      maxlength={6}
                                      placeholder="Enter six digits otp"
                                    />
                                  </div>
                                  {otp !== "" && (
                                    <small className="text-danger">
                                      {isOtpValid
                                        ? isVerified
                                          ? otpMessage
                                          : otpMessage
                                        : ""}
                                    </small>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <p className="register-link">
                            Not yet Registered -
                            <NavLink className="" to="/register">
                              Register
                            </NavLink>
                          </p> */}

                          {captchaToken &&
                            mobileNumber.length == 10 &&
                            hideotpbtn && (
                              <div className="generate-otp-btn-cover">
                                <button
                                  disable={isLoading}
                                  type="button"
                                  className=""
                                  onClick={generateOtp}
                                >
                                  {isLoading
                                    ? "generating otp..."
                                    : "Generate Otp"}
                                </button>
                              </div>
                            )}

                          {isOtpValid && (
                            <button
                              className="loginbtn"
                              type="button"
                              onClick={handlelogin}
                              disabled={isLoading}
                            >
                              {isLoading ? "please wait..." : "Login"}
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-center" />
    </>
  );
};

export default Login;
