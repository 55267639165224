import React from 'react'
import Loader from "./LoadingAnimation"

const FullLoader = () => {
  return (
    <div className='full-loader-main-cover-wrapper'>
        <Loader/>
    </div>
  )
}

export default FullLoader