import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { wellness_medicine , wellness_diagnostics , wellness_teleconsultation } from "../Assets";

const Wellness_corner = () => {
  return (
    <>
      <Header />
      <div className="wellness_main_cover_wrapper mt-5">
        <h3 className="text-center mb-4">Wellness Corner</h3>

        <div className="main-wellness-product-cover">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 mt-lg-3 mb-3">
                <div className="main-wellness-product-box">
                  <div className="main-wellness-img-box">
                    <img
                      src={wellness_medicine}
                      alt="families"
                    />
                  </div>
                  <div className="main-wellness-content-box">
                    <h5>Medicine</h5>
                    <p className="mb-0">
                      Doorstep delivery with special discounts
                      <br />
                      
                    </p>
                    <span className="">
                        <Link
                          className=""
                          to="https://app.medibhai.com/wellness-medicine"
                          target="_blank"
                        >
                          Know More
                        </Link>
                      </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-lg-3 mb-3">
                <div className="main-wellness-product-box">
                  <div className="main-wellness-img-box">
                    <img
                      src={wellness_diagnostics}
                      alt="families"
                    />
                  </div>
                  <div className="main-wellness-content-box">
                    <h5>Diagnostics</h5>
                    <p className="mb-0">
                      Lab tests and health check-ups at home
                      <br />
        
                    </p>
                    <span className="">
                        <Link
                          className=""
                          to="https://app.medibhai.com/diagnostics"
                          target="_blank"
                        >
                          Know More
                        </Link>
                      </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-lg-3 mb-3">
                <div className="main-wellness-product-box">
                  <div className="main-wellness-img-box">
                    <img
                      src={wellness_teleconsultation}
                      alt="families"
                    />
                  </div>
                  <div className="main-wellness-content-box">
                    <h5>Teleconsultation</h5>
                    <p className="mb-0">
                      Get the consultation at your comfort place with our panel
                      of experts
                      <br />
                      
                    </p>
                    <span className="">
                        <Link
                          className=""
                          to="https://app.medibhai.com/teleconsult"
                          target="_blank"
                        >
                          Know More
                        </Link>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wellness_corner;
