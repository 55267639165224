import React from 'react';
import { data_not_found_icon } from '../Assets/index';

const NoDataFound = ({msg}) => {
  return (
    <div className="NoDataFound_main_wrapper">
      <div className="Nodatafound-img-box">
        <img src={data_not_found_icon} alt="Data not found" />
      </div>
      <h4 class="text-center">{msg}</h4>
    </div>
  );
};

export default NoDataFound;
