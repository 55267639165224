import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import React from 'react'
import { IoIosAdd } from "react-icons/io";



function ExistClaimModal(props){


  const handleClaimExistData = (claimData) =>{
    props.setExistingData(claimData)
    props.setExistClaimModalShow(false)
    localStorage.removeItem('memberID')
  }


  return (
    <div className='claim-exist-modal-cover-wrapper'>
        
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <h5>Existing Claims</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <div className='existing-claims-cover-ui'>
                    <div className="add-new-btn-cover d-flex justify-content-between mb-3">
                        <h6 className='mb-0'>{props?.memberName}</h6>
                        <button className='add-new-btn' onClick={() =>props.setExistClaimModalShow(false)}>
                          <IoIosAdd/>
                          Add New 
                        </button>
                    </div>
                    <p className=" mb-3">Note : Please select existing record to complete journey or click on add new button to start new journey
</p>
                    <div className="existing-claims-card-cover-wrapper">
                      <div className="row">
                        {props?.claimExistData?.length > 0 && props?.claimExistData?.map((claimData) =>{
                          return (
                            <div className="col-lg-6">
                            <div className="existing-claims-card" onClick={() => handleClaimExistData(claimData)}>
                              <div className='existing-list-card d-flex justify-content-between align-items-center mb-2' >
                                <h5>Hospital Name -</h5>
                                <h5>{claimData?.hospital_name}</h5>
                              </div>
                              <div className='existing-list-card d-flex justify-content-between align-items-center mb-2'>
                                <h5>Hospitalization Date -</h5>
                                <h5>{claimData?.hospitalization_date}</h5>
                              </div>
                              <div className='existing-list-card d-flex justify-content-between align-items-center mb-2'>
                                <h5>Date of Discharge -</h5>
                                <h5>{claimData?.discharge_date}</h5>
                              </div>
                            </div>
                          </div>
                          )
                        })}
                      </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default ExistClaimModal

